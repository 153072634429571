import React from "react";
import { Form } from "react-bootstrap";
import styles from "./tg-input.module.scss";
import TGIcon from "../tg-icon";
import { useEffect, useRef, useState } from "react";
import { checkValidity, isValidEmail, isValidRopId } from "../../utils/helper";

export interface Props {
  label?: string;
  onChange: (e: any) => void;
  onKeyDown?: (e: any) => void;
  type?: string;
  isError?: boolean;
  ref?: any;
  errorLabel?: any;
  errorURLLabel?: any;
  errorLabelSecondary?: any;
  placeholder?: string;
  value?: string;
  name?: string;
  customClassName?: string;
  secondaryCustomClassname?: string;
  tertiaryCustomClassname?: string;
  enrollLabel?: string;
  isEnroll?: boolean;
  disables?: boolean;
  maxLength?: number;
  validation?: string;
  focus?: boolean;
}

const TGInput = React.forwardRef((props: Props, ref) => {
  const [inputValue, setInputValue] = useState(props.value);
  const [currentType, setCurrentType] = useState(props.type);
  useEffect(() => {
    if (props?.value !== "") {
      setInputValue(props.value);
    } else setInputValue("");
  }, [props.value]);
  const handleChange = (e: any) => {
    const convertToUppercase =
      props.type !== "password" &&
      props.name !== "email" &&
      props.name !== "confirmEmail" &&
      props.name !== "emailAddress"
        ? e.target.value.toUpperCase()
        : e.target.value;
    if (checkValidity(props?.validation, e.target.value)) {
      setInputValue(e.target.value);
      e.target.value = convertToUppercase;
      props.onChange(e);
    }
  };
  const handleShowPassword = () => {
    if (currentType === "password") {
      setCurrentType("text");
    } else setCurrentType("password");
  };

  return (
    <Form.Group
      controlId="exampleForm.ControlInput1"
      className={`${props.customClassName} flex-grow-1`}
    >
      <Form.Label
        className={`${
          !props.isEnroll
            ? styles["tg-input-label"]
            : styles["tg-enroll-input-label"]
        }`}
      >
        {props.label}
      </Form.Label>
      {props.type === "password" && (
        <div
          className={`${styles["tg-input-eye-icon"]}  ${
            props.isError && styles["tg-input-error-eye-icon"]
          }`}
          onClick={handleShowPassword}
        >
          {currentType === "password" ? (
            <TGIcon icon="hidden-eye-icon" fillColor="" size="16" />
          ) : (
            <TGIcon icon="show-eye-icon" fillColor="" size="16" />
          )}
        </div>
      )}
      <div
        className={`${props.secondaryCustomClassname} flex-grow-1 input-control`}
      >
        <Form.Control
          className={`${styles["tg-input-box"]} ${
            props.tertiaryCustomClassname
          }  ${props.isError && styles["tg-input-error"]}`}
          onChange={handleChange}
          type={currentType || "text"}
          name={props.name}
          value={
            props.type !== "password" &&
            props.name !== "email" &&
            props.name !== "confirmEmail" &&
            props.name !== "emailAddress"
              ? inputValue?.toUpperCase()
              : inputValue
          }
          ref={ref}
          placeholder={props.placeholder || ""}
          disabled={props.disables}
          maxLength={props.maxLength}
          autoComplete="off"
          autoFocus={props.focus}
          onKeyDown={props.onKeyDown}
        />
        {props.isError && (
          <div className={styles["tg-input-error-icon"]}>
            <TGIcon icon="error-icon" fillColor="" size="16" />
          </div>
        )}
      </div>
      {props.isError && (
        <div
          className={`${styles["tg-input-error-label"]} ${
            styles[props.customClassName ? props.customClassName : ""]
          }`}
        >
          {props.errorLabel}
        </div>
      )}
    </Form.Group>
  );
});

export default TGInput;
