import React from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import styles from "./tg-modal.module.scss";
import TGButtonVariants from "../tg-button-variants";
import TGIcon from "../tg-icon";
import useScreenSize from "../../utils/hook/useScreenSize";

interface ModalProps {
  show: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  customClassNamePrimary?: string;
  buttonLabel?: string;
  heading?: string;
  headingClass?: string;
  buttonPresent?: boolean;
  customModalClass?: string;
  customOffCanvasClass?: string;
  centered?: boolean;
  backdrop?: any;
  backdropClassName?: any;
  bodyClassName?:string;
}

const TGModal: React.FC<ModalProps> = (props) => {
  const isDesktop = useScreenSize().device === "desktop";
  return (
    <>
      {isDesktop ? (
        <Modal
          show={props.show}
          onHide={props.handleClose}
          size="lg"
          dialogClassName={`${styles.customModal} ${props.customModalClass}`}
          className={`${
            styles[
              props.customClassNamePrimary ? props.customClassNamePrimary : ""
            ]
          } desktop-modal`
        }
          scrollable={true}
          centered={props.centered}
          backdrop={props.backdrop}
        >
          <Modal.Header>
            <div
              className={`${
                styles[props.headingClass ? props.headingClass : ""]
              }`}
            >
              {props.heading || ""}
            </div>
            <div onClick={props.handleClose} className={styles.closeIcon}>
              <TGIcon
                fillColor={"#181B47"}
                icon={"close-bold-icon"}
                size={"24"}
              />
            </div>
          </Modal.Header>
          <Modal.Body className={`${props.bodyClassName}`}>{props.children}</Modal.Body>
          {props.buttonPresent && (
            <Modal.Footer>
              <TGButtonVariants
                label={props.buttonLabel}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#684B9B"
                bgColorHover="#3D2D5E"
                padding="8px 24px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="61px"
                width="191px"
                onClick={props.handleClose}
              />
            </Modal.Footer>
          )}
        </Modal>
      ) : (
        <Offcanvas
          show={props.show}
          onHide={props.handleClose}
          placement="bottom"
          className={`${styles.customOffCanvas} ${props.customOffCanvasClass}`}
          backdrop={props?.backdrop}
          backdropClassName={props?.backdropClassName}
        >
          <Offcanvas.Header>
            <div className={styles.offCanvasHeading}>{props.heading || ""}</div>
            <div onClick={props.handleClose}>
              <TGIcon fillColor={"#181B47"} icon={"close-bold-icon"} />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className={props.bodyClassName}>{props.children}</Offcanvas.Body>
          {props.buttonPresent && (
            <div className={styles.offCanvasFooter}>
              <TGButtonVariants
                label={props.buttonLabel}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#684B9B"
                bgColorHover="#3D2D5E"
                padding="8px 24px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="61px"
                width="100%"
                onClick={props.handleClose}
              />
            </div>
          )}
        </Offcanvas>
      )}
    </>
  );
};

export default TGModal;
