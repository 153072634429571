// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rHRZpeOgbYxm9wagFT5G > div {
  width: max-content;
  margin: auto;
  background: unset;
  border: unset;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-main-loader/tg-main-loader.module.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAAR","sourcesContent":[".loader-container {\n    > div{\n        width: max-content;\n        margin: auto;\n        background: unset;\n        border: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `rHRZpeOgbYxm9wagFT5G`
};
export default ___CSS_LOADER_EXPORT___;
