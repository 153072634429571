// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eu6XhaDsEvrn60UaP5AM > div {
  width: max-content;
  margin: auto;
  background: unset;
  border: unset;
}

.rORRqMs5zmef1yoJOB2D {
  background-color: white;
  opacity: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-loader/tg-loader.module.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAAR;;AAIA;EACI,uBAAA;EACA,yBAAA;AADJ","sourcesContent":[".loader-container {\n    > div{\n        width: max-content;\n        margin: auto;\n        background: unset;\n        border: unset;\n    }\n}\n\n.loader-backdrop {\n    background-color: white;\n    opacity: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `eu6XhaDsEvrn60UaP5AM`,
	"loader-backdrop": `rORRqMs5zmef1yoJOB2D`
};
export default ___CSS_LOADER_EXPORT___;
