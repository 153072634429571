import React, { useEffect } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import styles from "./tg-loader.module.scss";
import { useTranslation } from "react-i18next";
import { geolocationDataRequest } from "../../OSCI/src/slice/header/geolocationSlice";
import { useDispatch, useSelector } from "react-redux";

interface PageSkeletonProps {
  isLoading?: boolean;
}
const TGLoader: React.FC<PageSkeletonProps> = ({ isLoading = true }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const res = useSelector((state: any) => state.osciReducer?.geolocation);
  console.log("check check", res);
  const fetchGeolocation = async () => {
    await dispatch(geolocationDataRequest());
  };
  useEffect(() => {
    fetchGeolocation();
  }, []);

  useEffect(() => {
    if (
      res?.hasData &&
      !res?.isLoading &&
      typeof res?.geolocationDetails?.headers?.["cloudfront-viewer-country"] !==
        "undefined"
    ) {
      const countryCode =
        res?.geolocationDetails?.headers?.["cloudfront-viewer-country"];
      i18n.changeLanguage(`en-${countryCode?.toLowerCase()}`);
    } else if (!res.hasData && !res.isLoading) {
      i18n.changeLanguage("en-th");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    }
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  });

  if (isLoading) {
    return (
      <div>
        <Modal
          show={true}
          centered
          className={styles["loader-container"]}
          backdropClassName={styles["loader-backdrop"]}
        >
          <ModalBody
            style={{
              width: "max-content",
              margin: "auto",
              background: "unset",
              border: "unset",
            }}
          >
            <Spinner animation="border" variant="primary" /> <br />
          </ModalBody>
        </Modal>
      </div>
    );
  }
};
export default TGLoader;
