import React, { useEffect, useState } from "react";
import { Trips } from "../../OSCI/src/models/models";
import styles from "./tg-multi-card.module.scss";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../utils/helper";
import { config } from "../../config/global";
import { Carousel, useCarousel } from "nuka-carousel";
import TGButtonVariants from "../../shared/tg-button-variants";
import useScreenSize from "../../utils/hook/useScreenSize";
import TGModal from "../../shared/tg-modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteTripCardDataRequest } from "../../OSCI/src/slice/deleteTripCardSlice";
import {
  getTripDataFailure,
  getTripDataSuccess,
} from "../../OSCI/src/slice/tripSlice";
import { RootState } from "../../OSCI/src/slice/RootReducer";
import TGIcon from "../tg-icon";
import Timer from "../../shared/tg-timer";

interface Props {
  flightData: any;
  page: string;
  isMiniProfile?: boolean;
}

const TGMultiCard: React.FC<Props> = ({ flightData, page, isMiniProfile }) => {
  const { t } = useTranslation();
  const deleteTripInfo = useSelector(
    (state: RootState) => state?.osciReducer?.deleteTripCard?.upcomingTrips
  );
  const dispatch = useDispatch();
  const [showDeleteCard, setShowDeleteCard] = useState(false);
  const [deletePnr, setDeletePnr] = useState("");
  const IMAGE_API = process.env.REACT_APP_TG_IMAGE_SEARCH_URL;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const moment = require("moment");
  const MANAGE_BOOKING_API_URL = config.TEAMSITE_UPCOMING_TRIPS_API;

  //  Function is called when the it cant find and image for assigned destination or multicity.
  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = "../assets/multiCity.svg";
  };

  const dataAN = flightData?.pnrDetails?.map((x: any) => {
    return x.flightDetails;
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (!isMiniProfile) {
      if (showDeleteCard) {
        document.documentElement.style.setProperty("--body-overflow", "hidden");
      } else {
        document.documentElement.style.setProperty("--body-overflow", "auto");
      }
    }
  }, [isMiniProfile, showDeleteCard]);

  //  Function called when user comes from Manage booking
  const handleViewDetails = (lastName: string, bookingNo: string) => {
    const language: any = getCookie("config");
    const manageBookingUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.MANAGE_BOOKING_API;
    const lang = language?.languageCode.toLowerCase();
    const manageBookingQueryParams =
      "booking_no=" + bookingNo + "&lastname=" + lastName + "&lang=" + lang;
    const amadeusRedirectionUrl =
      manageBookingUrl + "?" + manageBookingQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  //  Functon is called when user comes from Check-In
  const handleCheckIn = (lastName: string, bookingNo: string) => {
    const checkinUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.CHECKIN_API;
    const checkinQueryParams = "pnr=" + bookingNo + "&lname=" + lastName;
    const amadeusRedirectionUrl = checkinUrl + "?" + checkinQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  const handleNavigate = () => {
    window.open("/");
  };

  //  Funciton handles the check-in button to enable and disable
  const CheckInButton = (checkInDate: any) => {
    let date = moment(checkInDate, "DD-MM-YY HH:mm");
    const currentDate = moment();
    const checkinDuration = date.diff(currentDate, "h");
    return checkinDuration <= 24 ? false : true;
  };

  const renderMultiCityAirportCode = (flightData: any) => {
    //flight ref number of connecting flights
    const flight = [];
    let content = "";
    let lastArray = [];
    let startVal = 0;
    let count = 0;
    //pnr length
    for (let l: number = 0; l < flightData?.pnrDetails?.length; l++) {
      //multicity condition
      if (flightData?.pnrDetails[l]?.tripType.toUpperCase() === "MULTI_CITY") {
        //segment group length
        for (
          let i: number = 0;
          i < flightData?.pnrDetails[l]?.segmentGroups?.length;
          i++
        ) {
          //connection segments length
          for (
            let j: number = 0;
            j <
            JSON.parse(
              flightData?.pnrDetails[l]?.segmentGroups[i]?.connectionSegments
            )?.length;
            j++
          ) {
            flight.push(
              JSON.parse(
                flightData?.pnrDetails[l]?.segmentGroups[i]?.connectionSegments
              )[j]
            );
          }
        }
        for (
          let last: number = 0;
          last < flightData?.pnrDetails[l]?.segmentGroups?.length;
          last++
        ) {
          lastArray.push(
            JSON.parse(
              flightData?.pnrDetails[l]?.segmentGroups[last].connectionSegments
            ).reverse()[0]
          );
        }
        for (
          let k: number = 0;
          k < flightData?.pnrDetails[l]?.flightDetails?.length;
          k++
        ) {
          if (
            Number(flightData?.pnrDetails[l]?.flightDetails[k]?.flightRef) ===
            flight[count]
          ) {
            if (
              lastArray[startVal] ===
              Number(flightData?.pnrDetails[l]?.flightDetails[k]?.flightRef)
            ) {
              content += `${flightData?.pnrDetails[l]?.flightDetails[k]?.departureCode}-${flightData?.pnrDetails[l]?.flightDetails[k]?.arrivalCode}//`;
              startVal++;
            } else {
              content += `${flightData?.pnrDetails[l]?.flightDetails[k]?.departureCode}-`;
            }
            if (lastArray.length > 1) {
              count++;
            }
          } else if (
            flightData?.pnrDetails[l]?.flightDetails[
              k
            ]?.flightRef.toString() ===
            flightData?.pnrDetails[l]?.flightDetails.length.toString()
          ) {
            content += `${flightData?.pnrDetails[l]?.flightDetails[k]?.departureCode}-${flightData?.pnrDetails[l]?.flightDetails[k]?.arrivalCode}`;
          } else {
            content += `${flightData?.pnrDetails[l]?.flightDetails[k]?.departureCode}-${flightData?.pnrDetails[l]?.flightDetails[k]?.arrivalCode}//`;
          }
        }
      }
    }
    return content;
  };

  useEffect(() => {
    if (deleteTripInfo?.status === 200) {
      dispatch({
        type: config.FETCH_DATA_CONSTANT,
        url: MANAGE_BOOKING_API_URL,
        isTeamsite: false,
        successAction: getTripDataSuccess,
        errorAction: getTripDataFailure,
      });
    }
  }, [deleteTripInfo]);

  const deleteHandle = () => {
    setShowDeleteCard(false);
  };

  const showDeletedTripData = (detail: any) => {
    setShowDeleteCard(true);
    setDeletePnr(detail?.pnr);
  };

  const deleteTripCardHandler = () => {
    setShowDeleteCard(false);
    setDeletePnr("");
    dispatch(deleteTripCardDataRequest(deletePnr));
  };

  // Card UI for upcoming trips
  const Card = () => {
    return flightData?.pnrDetails
      ?.filter((z: any) => !z.deleteFlag)
      ?.map((cardsResp: any, index: any) => (
        <div
          className={`${
            page === "MP"
              ? styles.miniProfileCardContainer
              : styles.cardContainer
          }`}
        >
          <img
            src={
              cardsResp?.tripType === "MULTI_CITY"
                ? IMAGE_API + "" + ".jpg"
                : IMAGE_API +
                  cardsResp.flightDetails[cardsResp.flightDetails.length - 1]
                    .arrivalCode +
                  ".jpg"
            }
            alt="Upcoming Trips"
            className={`${styles.cardImage} ${
              page === "MP" && styles["mini-profile-card-image"]
            }`}
            onError={handleError}
          />
          <div
            className={
              page === "CiN"
                ? `${styles.cardOverlay} ${styles.cardOverlayCiN}`
                : page === "MP"
                ? `${styles.cardOverlay} ${styles.cardOverlayMP}`
                : styles.cardOverlay
            }
          >
            <div className={styles.tripDetailsContainer}>
              <div className="w-100">
                {(cardsResp?.tripType === "MULTI_CITY" &&
                (page === "MB" || page === "MP") ? (
                  <span className={styles.tripTextMultiCity}>
                    {t("label_trip_multicity")} {t("label_trip_to")}
                  </span>
                ) : cardsResp?.tripType === "MULTI_CITY" && page === "CiN" ? (
                  <span className={styles.tripTextMultiCity}>
                    {t("label_trip_multicity")} {t("label_trip_to")}
                  </span>
                ) : cardsResp?.tripType === "ROUND" &&
                  (page === "MB" || page === "MP") ? (
                  <span className={styles.tripText}>
                    {t("label_trip_round")} {t("label_trip_to")}
                  </span>
                ) : cardsResp?.tripType === "ROUND" && page === "CiN" ? (
                  <span className={styles.tripText}>
                    {t("label_trip_round")} {t("label_trip_to")}
                  </span>
                ) : cardsResp?.tripType === "ONE_WAY" &&
                  (page === "MB" || page === "MP") ? (
                  <span className={styles.tripText}>
                    {t("label_book_widget_one_way")} {t("label_trip_to")}
                  </span>
                ) : cardsResp?.tripType === "ONE_WAY" && page === "CiN" ? (
                  <span className={styles.tripText}>
                    {t("label_book_widget_one_way")} {t("label_trip_to")}
                  </span>
                ) : (
                  ""
                )) &&
                  // {/* upcoming delete trip close button */}
                  cardsResp?.addedTrip && (
                    <div
                      onClick={() => {
                        showDeletedTripData(cardsResp);
                      }}
                      className={styles["close-button"]}
                    >
                      <TGIcon
                        icon="mini-profile-close"
                        size=""
                        fillColor="#FFF"
                      />
                    </div>
                  )}
              </div>
              <div className={styles["multi-card-upcoming-trip-static-text"]}>
                {(cardsResp?.tripType === "MULTI_CITY" &&
                  t("label_trip_multicity") + " " + t("label_trip_to")) ||
                  (cardsResp?.tripType === "ROUND" &&
                    t("label_trip_round") + " " + t("label_trip_to")) ||
                  (cardsResp?.tripType === "ONE_WAY" &&
                    t("label_book_widget_one_way") + " " + t("label_trip_to"))}
              </div>
              <span
                className={
                  cardsResp?.tripType === "MULTI_CITY"
                    ? styles.titleTextMulti
                    : styles.titleText
                }
              >
                {cardsResp.tripType === "MULTI_CITY"
                  ? renderMultiCityAirportCode(flightData)
                  : null}

                {cardsResp.tripType === "ONE_WAY" &&
                  `${
                    cardsResp.flightDetails[cardsResp.flightDetails.length - 1]
                      .destination
                  }`}
                {cardsResp.tripType === "ROUND" &&
                  `${
                    cardsResp.flightDetails[cardsResp.flightDetails.length - 1]
                      .origin
                  }`}
              </span>
              <span className={styles.timeText}>
                {cardsResp.tripType === "ONE_WAY"
                  ? moment(
                      cardsResp.flightDetails[
                        cardsResp.flightDetails.length - 1
                      ].depatureDate,
                      "DD-MM-YY HH:mm"
                    ).format("DD MMM YYYY, HH:mm")
                  : `${moment(
                      cardsResp.flightDetails[0].depatureDate,
                      "DD-MM-YY HH:mm"
                    ).format("DD MMM YYYY, HH:mm")} - ${moment(
                      cardsResp.flightDetails[
                        cardsResp.flightDetails.length - 1
                      ].depatureDate,
                      "DD-MM-YY HH:mm"
                    ).format("DD MMM YYYY, HH:mm")}`}
              </span>
              <span className={styles.airportText}>
                {cardsResp.tripType === "MULTI_CITY" || page === "MP"
                  ? ""
                  : // displaying last array of object
                    cardsResp.flightDetails[cardsResp.flightDetails.length - 1]
                      .departureAirportName}
              </span>
              {page !== "MP" && (
                <span className={styles.airportText}>
                  {t("label_booking_ref")}: {cardsResp.pnr}
                </span>
              )}
            </div>
            <div
              className={`${styles.cardButton} ${
                page === "MP" && styles["mini-profile-trip"]
              }`}
            >
              <div className={styles.iconContainer} onClick={handleNavigate}>
                <span className={styles.buttonText}>
                  {page === "MP"
                    ? t("label_view_booking")
                    : t("label_view_detail")}
                </span>
                <img
                  src={"../assets/arrow-right-icon.png"}
                  alt="View Details"
                />
              </div>
              {page === "CiN"
                ? isDesktopView && (
                    <div
                      className={styles.iconContainer}
                      onClick={() =>
                        handleViewDetails(cardsResp.lastName, cardsResp.pnr)
                      }
                    >
                      <span className={styles.buttonText}>
                        {t("label_book_widget_manage_booking")}
                      </span>
                      <img
                        src={"../assets/arrow-right-icon.png"}
                        alt="Manage Booking"
                      />
                    </div>
                  )
                : page !== "MP" && (
                    <div
                      className={styles.iconContainer}
                      onClick={() =>
                        handleViewDetails(cardsResp.lastName, cardsResp.pnr)
                      }
                    >
                      <span className={styles.buttonText}>
                        {t("label_book_widget_manage_booking")}
                      </span>
                      <img
                        src={"../assets/arrow-right-icon.png"}
                        alt="Manage Booking"
                      />
                    </div>
                  )}
              {page === "CiN" ? (
                <TGButtonVariants
                  label={t("label_book_widget_check_in")}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#FFFFFF"
                  padding="16px"
                  textColor="#684B9B"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  borderDisabled="0.791px solid #E3D4FE"
                  bgColorDisabled="#E3D4FE"
                  boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColorHover="#E3D4FE"
                  borderHover="0.791px solid #E3D4FE"
                  boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  width={isDesktopView ? "25%" : "55%"}
                  height={isDesktopView ? "70%" : "60%"}
                  onClick={() =>
                    handleCheckIn(cardsResp.lastName, cardsResp.pnr)
                  }
                  // disabled={CheckInButton(details.depatureDateUTC)}
                  disabled={CheckInButton(
                    cardsResp.flightDetails.map(
                      (val: { depatureDateUTC: any }) => val.depatureDateUTC
                    )
                  )}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ));
  };

  //  Ui for Customized Dots used in Carousel.
  const CustomDots = () => {
    const { totalPages, currentPage, goToPage, wrapMode, goBack, goForward } =
      useCarousel();

    const className = (index: number) => {
      let value = styles.indicators;
      if (currentPage === index) {
        value = styles.activeIndicators;
      }
      return value;
    };

    return (
      <div className={styles.cardIndicators}>
        {isDesktopView && page !== "MP" && (
          <img
            src={"/assets/arrowRightNK.svg"}
            className={styles.prevNavClassName}
            onClick={goBack}
            alt="right Arrow"
          />
        )}

        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => goToPage(index)}
            className={className(index)}
            title="next"
          />
        ))}
        {isDesktopView && page !== "MP" && (
          <img
            src={"/assets/arrowRightNK.svg"}
            className={styles.nextNavClassName}
            onClick={goForward}
            alt="right"
          />
        )}
      </div>
    );
  };

  //  UI for Multiple card Carousel from nuka-carousel
  const CardCarousel = () => {
    return (
      <Carousel
        className={styles["nuka-carousel"]}
        showDots={true}
        scrollDistance={isDesktopView ? 490 : windowWidth - 26}
        swiping={true}
        keyboard={true}
        autoplay={false}
        autoplayInterval={6000}
        wrapMode="wrap"
        dots={<CustomDots />}
      >
        <Card />
      </Carousel>
    );
  };

  // Funciton for defining how many cards should be displayed at specific trips and at responsive.
  const UpcomingtripCards = () => {
    return dataAN && dataAN.length >= 2 ? (
      <CardCarousel />
    ) : page === "MP" ? (
      <Card />
    ) : windowWidth > 910 ? (
      <div className={styles.container}>
        <Card />
      </div>
    ) : (
      <CardCarousel />
    );
  };

  return (
    <>
      <UpcomingtripCards />
      {/* delete trip card modal */}
      <div className={styles["modal-card-wrapper-mobile-view"]}>
        <TGModal
          show={showDeleteCard}
          handleClose={deleteHandle}
          centered={true}
          customModalClass={styles["trip-card-model"]}
          customOffCanvasClass={styles["trip-card-offcanvas"]}
          backdropClassName={styles["trip-card-backdrop"]}
        >
          <div className={styles.tcModalFlex}>
            <div className={styles.tcModal}>
              <img
                className={styles.deletetripimage}
                src="../assets/DeleteTripImage.png"
                alt="deletetripimage"
              />
              <div className={styles.modalContent}>
                <span className={styles.modalHeading}>
                  {t("delete_trip_heading")}
                </span>
                <div className={styles.modalDescription}>
                  {t("delete_trip_description")}
                </div>
              </div>
            </div>
            <div className={styles.dbuttonContainer}>
              <TGButtonVariants
                label={t("button_label_keep")}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#F5EFFF"
                bgColorHover="#F5EFFF"
                padding="8px 24px"
                textColor="#381B6B"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                onClick={deleteHandle}
                borderHover="none"
                width={isDesktopView ? "128px" : "50%"}
              />
              <TGButtonVariants
                label={t("button_label_delete")}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#381B6B"
                bgColorHover="#381B6B"
                padding="8px 24px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                onClick={deleteTripCardHandler}
                borderHover="none"
                width={isDesktopView ? "170px" : "50%"}
              />
            </div>
          </div>
        </TGModal>
      </div>
    </>
  );
};

export default TGMultiCard;
