import React, { useEffect } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import styles from "./tg-main-loader.module.scss"

interface PageSkeletonProps {
  isLoading?: boolean;
}
const TGMainLoader: React.FC<PageSkeletonProps> = ({
  isLoading = true,
}) => {

  if (isLoading) {
    return (
      <div>
         <Modal show={true} centered className={`${styles["loader-container"]} main-loading-spinner`} backdropClassName={`${styles["loader-backdrop"]} main-loading-spinner`}>
           <ModalBody  style={{width: "max-content", margin: "auto", background: "unset", border: "unset"}}>
           <Spinner animation="border" variant="primary"/> <br />
           </ModalBody>
         </Modal>
      </div>
    );
  }
};
export default TGMainLoader;
