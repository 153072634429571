import React, { useState } from "react";

import { Button, Card, Carousel, CarouselItem, Stack } from "react-bootstrap";
import TGButtonVariants from "../tg-button-variants";

import styles from "./tg-inspiration-carousel.module.scss";
import {
  InspirationCarouselProps,
  InspirationCarouselCardProps,
  IdCarouselData,
} from "../../OSCI/src/models/models";
import TGPlaceholder from "../../shared/tg-placeholder";

import { extractSubArray } from "../../utils/helper";
import useScreenSize from "../../utils/hook/useScreenSize";

const handleChange = (buttonURL: string) => {
  window.open(buttonURL);
};

const InspirationDealCarouselCard: React.FC<InspirationCarouselCardProps> = ({
  inspirationItem,
}) => {
  return (
    <Card
      className={`promoCard ${styles.promoCards}`}
      onClick={() => handleChange(inspirationItem?.buttonLink)}
    >
      <Card.Img
        src={inspirationItem?.image}
        alt={inspirationItem?.altText}
        className={`promoImage ${styles.promoImages}`}
      />
      <div className={styles.imageOverlay}></div>
      {inspirationItem?.fromtoTitle && (
        <Card.ImgOverlay className={styles.cardOverlay}>
          <div className={styles.cardBody}>
            <div className={styles.cardCaption}>
              <div className={styles.cardTitleWrapper}>
                <Card.Subtitle className={styles.cardSubTitle}>
                  {inspirationItem?.fromtoTitle}
                </Card.Subtitle>
                <Card.Title className={styles.cardTitle}>
                  {inspirationItem?.locationTitle}
                </Card.Title>
              </div>
              <div className={styles.descriptionWrapper}>
                <Card.Text className={styles.descriptionText}>
                  {inspirationItem?.classTitle}
                </Card.Text>
                <Card.Text className={styles.amountText}>
                  {inspirationItem?.amountText}
                </Card.Text>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <TGButtonVariants
                label={inspirationItem?.buttonText}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#FFFFFF"
                padding="8px 12px 8px 16px"
                textColor="#684B9B"
                fontFamily="Inter"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="42px"
                width="120px"
                bgColorHover="#b3b2b6"
                onClick={() => handleChange(inspirationItem?.buttonLink)}
              />
            </div>
          </div>
        </Card.ImgOverlay>
      )}
    </Card>
  );
};

const TGInspirationDealCarousel: React.FC<InspirationCarouselProps> = ({
  inspirationData,
  isLoading,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  const isMobile = useScreenSize()?.deviceSize?.width < 768;

  const handleSelect = (selectedIndex: number) => {
    setCurrentIndex(selectedIndex);
  };

  const handleSlideStart = () => {
    setTransitioning(true);
  };

  const handleSlideEnd = () => {
    setTransitioning(false);
  };

  const PlaceHolderSlide = () => (
    <Card className={`promoCard ${styles.promoCards}`}>
      <TGPlaceholder
        typeClass={Card.Img}
        loaderStyleClass="imageLoader"
        styleClass={`promoImage ${styles.promoImages}`}
        sizeClass={12}
        variant="HTMLElement"
        animationVariant="wave"
      />
      <div className={styles.imageOverlay}></div>
      <Card.ImgOverlay className={styles.cardOverlay}>
        <div className={styles.cardBody}>
          <div className={styles.cardCaption}>
            <div className={styles.cardTitleWrapper}>
              <TGPlaceholder
                typeClass={Card.Text}
                styleClass={styles.cardSubTitle}
                animationVariant="glow"
                variant="text"
                sizeClass={12}
                numberOfLines={2}
                loaderStyleClass="textLoader"
              />
            </div>
            <TGPlaceholder
              typeClass={Card.Text}
              styleClass={styles.descriptionText}
              animationVariant="glow"
              variant="text"
              sizeClass={12}
              numberOfLines={2}
              loaderStyleClass="textLoader"
            />
          </div>
          <div className={styles.buttonContainer}>
            <TGPlaceholder
              typeClass={Button}
              styleClass={styles.placeholderCardButton}
              loaderStyleClass="buttonLoader"
              sizeClass={3}
              variant="HTMLElement"
              animationVariant="wave"
              bgVariant="light"
            />
          </div>
        </div>
      </Card.ImgOverlay>
    </Card>
  );

  const step = isMobile
    ? 2
    : inspirationData.length > 2
    ? 5
    : inspirationData.length === 4
    ? 4
    : inspirationData.length === 1
    ? 1
    : 0;

  const carouselItems = extractSubArray(inspirationData, step);

  const CustomNextArrow = () => (
    <div className={styles["carousel-control-next"]}>
      <img
        src="./assets/rightArrowWhite.png"
        width={20}
        height={20}
        alt="next arrow"
      />
    </div>
  );

  const CustomPrevArrow = () => (
    <div className={styles["carousel-control-next"]}>
      <img
        src="./assets/leftArrowWhite.png"
        width={20}
        height={20}
        alt="previous arrow"
      />
    </div>
  );

  return (
    <>
      {isLoading ? (
        <PlaceHolderSlide />
      ) : inspirationData.length === 1 ? (
        <Carousel
          className={styles.promoCarousel}
          controls={inspirationData.length !== 1}
          indicators={inspirationData.length !== 1}
          touch
        >
          <CarouselItem>
            <InspirationDealCarouselCard inspirationItem={inspirationData[0]} />
          </CarouselItem>
        </Carousel>
      ) : (
        <Carousel
          className={styles.promoCarousel}
          activeIndex={currentIndex}
          onSelect={handleSelect}
          controls={inspirationData.length > 2}
          indicators={inspirationData.length > 2}
          onSlide={handleSlideStart}
          onSlid={handleSlideEnd}
          nextIcon={<CustomNextArrow />}
          prevIcon={<CustomPrevArrow />}
          touch
        >
          {carouselItems.map((carouselItem: any[], index: number) => (
            <Carousel.Item
              key={index}
              className={`${transitioning && styles.transitioning}`}
            >
              <Stack direction="horizontal">
                {carouselItem.map((card: IdCarouselData, cardIndex: number) => (
                  <div
                    className={`${
                      carouselItems.length > 2
                        ? cardIndex < 2
                          ? styles.currentSlide
                          : styles.upcomingSlide
                        : styles.slide
                    } ${carouselItems.length === 2 && styles.currentSlide}`}
                    key={cardIndex}
                  >
                    <InspirationDealCarouselCard inspirationItem={card} />
                  </div>
                ))}
              </Stack>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default TGInspirationDealCarousel;
